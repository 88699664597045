import "./Experience.css";

import EntryRoom from "./EntryRoom/EntryRoom.jsx";
import Player from "./Player/Player.jsx";
import CameraControls from "./Utils/CameraControls.jsx";
import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/rapier";
import UiManager from "./UiManager";
import RoomPush from "./EntryRoom/RoomPush";
import { useState, useRef, useEffect, lazy } from "react";
import { Environment,} from "@react-three/drei"
import { useSnapshot } from "valtio";
import { AdaptiveDpr, AdaptiveEvents } from "@react-three/drei";
import { Perf } from "r3f-perf";
import ThemeWall from "./EntryRoom/ThemeWall/ThemeWall";
import InfoStation from "./EntryRoom/InfoStation";
import EntryRoomCollider from "./EntryRoom/EntryRoomCollider.jsx";
import { PlayerPositionProvider } from "./Utils/PlayerPositionContext";
import ThemeMonitor from "./EntryRoom/ThemeMonitor";
import { getGPUTier } from 'detect-gpu';
import { CacheBuster } from "react-cache-buster/dist/CacheBuster";
import packageFile from "../package.json"


import {

  stateIframeWindow,
  stateDevice,
  stateLoadingScreen,
  stateMemory,
  

} from "./store.jsx";


import {
  PerformanceMonitor,

} from "@react-three/drei";

import CameraControlsCharSwitch from "./Utils/CameraControslCharSwitch.jsx";

import CheckLandscapeMode from "./Utils/CheckLandscapeMode";
// import HolzarbeitCollider from "./Themes/Holzarbeit/HolzarbeitCollider.jsx";

const SalzabbauCollider = lazy(()=> import ("./Themes/Salzabbau/SalzabbauCollider"))
const MenschenCollider = lazy(()=> import ("./Themes/Menschen/MenschenCollider"))
const HandelswegeCollider = lazy(()=> import ("./Themes/Handelswege/HandelsWegeCollider"))
const KlimaCollider = lazy(()=> import ("./Themes/Klima/KlimaCollider"))
const MDRCollider = lazy(()=> import ("./Themes/MuseenDerRegion/MDR_Collider.jsx"))
const HolzarbeitCollider = lazy(()=> import ("./Themes/Holzarbeit/HolzarbeitCollider.jsx"))



export default function Experience() {

  const [gpuTier, setGpuTier] = useState(1);

  const isProduction = process.env.NODE_ENV === 'production';

  const {version} = packageFile
  useEffect(() => {
    const fetchGpuTier = async () => {
      try {
        const tier = await getGPUTier({
          mobileTiers: [0, 15, 40, 60], // Override mobileTiers here
          desktopTiers: [0, 15, 40, 80], // Override mobileTiers here
        });;

        setGpuTier(tier);
      } catch (error) {
        console.error("Error getting GPU tier:", error);
      }
    };

    fetchGpuTier();

    // Cleanup function if needed
    return () => {
      // Cleanup code
    };
  }, []);

  //  let detectedOS

  // useEffect(() => {
  //   // Detect OS and set context power preference
  //   const userAgent = window.navigator.userAgent;
  //   detectedOS = userAgent.match(/Mac/i) ? 'mac' : 'other'; // Simple OS detection
  //   if (detectedOS == 'mac') {
  //     // Set context power preference to "high-performance" for macOS
  //     if (canvasRef.current) {
  //       canvasRef.current.getContext('webgl', { contextPowerPreference : 'high-performance' });
  //     }
  //   }
  // }, []);

  const [gravity, setGravity] = useState(0);
  const snapLaodingscreen = useSnapshot(stateLoadingScreen);
  const snapIframeWindow = useSnapshot(stateIframeWindow);
// const snapStateMemory = useSnapshot(stateMemory)
  const canvasRef = useRef();

  const [aniRunEndDone, setAniRunEndDone] = useState(false);
  const [enableMPerfMon, setEnableMPerfMon] = useState(false);
  const [handy, setHandy] = useState(false);
  const [resizeTriggered, setResizeTriggered] = useState(false);

  const iPad =
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === "MacIntel" &&
      navigator.maxTouchPoints > 1); /* iPad OS 13 */

  stateDevice.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  stateDevice.isTablet = window.innerWidth >= 1000 && window.innerWidth <= 1200;

  const isIPhone = /iPhone|iPod/.test(navigator.userAgent);

  stateDevice.isIphone = isIPhone
  stateDevice.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
useEffect(() => {
  function handleResize() {
    setResizeTriggered(true);
  }

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []); // Empty dependency array for initial mount


useEffect(() => {
  if (resizeTriggered && window.innerWidth <= 1000 ) {
    setHandy(window.innerWidth <= 1000);
    setResizeTriggered(false);
  } else if(resizeTriggered){
    setHandy(false)
    setResizeTriggered(false);
  }
}, [resizeTriggered]); // Dependency on resizeTriggered state

  if (iPad) {
    stateDevice.isMobile = true;
    stateDevice.isTablet = true;
    stateMemory.scetchfabOff = true
  }
  if (isIPhone) {
    stateDevice.isMobile = true;
    stateMemory.scetchfabOff = true
  }
  if (handy) {
    stateDevice.isHandy = true;
   
  }





  const [light, setLight] = useState(true);

  const FOV = 60;

  const [dpr, setDpr] = useState(1);



const lowMemoryArray = ["apple a13 gpu"]; 
const lowPerformanceArray = ["apple a13 gpu","apple a15 gpu"];
const tierMeDownArray = ["intel mesa dri intel hd graphics 400"];  






  //setDpr([1.5]); // Set DPR for tier 2
  useEffect(() => {
    // Set DPR based on GPU tier
    if (gpuTier.tier === 1) {
      setDpr([0.5,1]); // Set DPR for tier 1
      stateMemory.memoryLowDevice = true
      stateMemory.performanceLowDevice = true
      stateMemory.scetchfabOff = true
     
      
    } else if (gpuTier.tier === 2 ) {
      stateMemory.performanceLowDevice = true
      if(stateDevice.isMobile){
      stateMemory.memoryLowDevice = true
    }
      setDpr([1,1.5]); // Set DPR for tier 2
    } else if (gpuTier.tier === 3) {

      setDpr([1.5,2]); // Set DPR for tier 3s
    } else if (gpuTier.tier === 0) {
      setLight(false);
      setDpr(0.5); // Set DPR for tier 3
      // stateMemory.memoryLowDevice = true
      // stateMemory.deviceToOld = true
      stateMemory.performanceLowDevice = true
      stateMemory.scetchfabOff = true
      setEnableMPerfMon(true);
    } 
  
    if (gpuTier.type === "FALLBACK") {
      // setDpr(1)
      setEnableMPerfMon(true);
    }

    
  }, [gpuTier.tier]);


  useEffect(() => {
  
    if (lowMemoryArray.includes(gpuTier.gpu) || isIPhone === true ) {
      stateMemory.memoryLowDevice = true;
      stateMemory.scetchfabOff = true
    }
  }, [gpuTier.gpu]);


  useEffect(() => {
  
    if (lowPerformanceArray.includes(gpuTier.gpu)) {
      stateMemory.performanceLowDevice = true
  
      
    }
    if (tierMeDownArray.includes(gpuTier.gpu)) {
      setDpr([0.5,1])
      stateMemory.performanceLowDevice = true
    }
    if (handy) {
      if(dpr > 1.5){
      setDpr(1.5)
    }
      stateMemory.performanceLowDevice = true
      stateMemory.scetchfabOff = true
  
    }
  }, [gpuTier.gpu]);







  return (
    <CacheBuster
    currentVersion={version}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={null} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <>




      <PlayerPositionProvider>
   { !snapLaodingscreen.loadingScreenAnimate && <Canvas
          ref={canvasRef}
          frameloop={snapIframeWindow.renderCanvas ? "always" : "never"}
          shadows={false}
          gl={{ antialias: true }}
          dpr={dpr}
          camera={{ fov: FOV, position: [0, 20, -180], far: 2000, near: 0.1 }}
          powerpreference="high-performance"
          // precision="lowp"
          // performance={{ min: 0.1 }}
          // antialias={false}
        >

<Environment 
    // preset = {"city"}
     background = {false}
    intensity={1}
    resolution={256}
    // color="black" // (optional = white)
    files= "./overallTextures/industrial_room.hdr"
  /> 
          {enableMPerfMon && (
            <PerformanceMonitor
              onIncline={() => {
              setDpr(2)
              stateMemory.deviceToOld = false
              }
            }
              onDecline={() => 
              // stateMemory.deviceToOld = true
              setDpr([0.2,1])
              }
              flipflops={4}
            ></PerformanceMonitor>
          )}
          {/* {aniRunEndDone && <PerformanceMonitor onChange={({ factor }) => setDpr(round(0.5 + 1.5 * factor, 1))}  ></PerformanceMonitor>} */}



          <CameraControls />
          <CameraControlsCharSwitch></CameraControlsCharSwitch>
          {light && (
            <directionalLight
              intensity={0.5}
              position={[30, 40, 60]}
              shadow={false}
            />
          )}

          <ambientLight intensity={0.6} shadow={false} />
          {/* <Preload all /> */}

         
            <Physics
           
              gravity={[0, gravity, 0]}
              interpolation={false} 
              timeStep={1 / 30}
              colliders={false}
            >
    
              <EntryRoomCollider />
             
             <MDRCollider />
             <MenschenCollider/>
            <SalzabbauCollider/>
            <KlimaCollider/>
            <HandelswegeCollider/> 
            <HolzarbeitCollider/>

          
           <ThemeWall />
     
           {aniRunEndDone &&  <Player />} 
            </Physics>
          <RoomPush></RoomPush>
        
         
          
         <InfoStation />
      
         <EntryRoom />
         <ThemeMonitor/>
          {/* <Perf 
// overClock= {true}
position ="top-left"></Perf> */}

         {/* <AdaptiveDpr pixelated/>  */}
             {/* <AdaptiveEvents />   */}
          {/* <AdaptivePixelRatio />  */}
     
        </Canvas>}
 
      </PlayerPositionProvider>
    
   

 
  
<UiManager setGravity= {setGravity} setAniRunEndDone = {setAniRunEndDone}></UiManager>
    
  
      {/* <div className="interfaceGPU"><h1>GPU Tier: {gpuTier.tier},{dpr},{gpuTier.type}</h1>
      <div>{gpuTier.gpu}</div>
      <div>{`isTablet: ${stateDevice.isTablet}`}</div>
      <div>{`isHandy: ${handy}`}</div>
      <div>{`memoryLow: ${ stateMemory.memoryLowDevice}`}</div>
    
    </div> */}

    
{/* {  !stateDevice.isMobile  &&  <div className="interfaceGPU">

      <div>{`Updates:`}</div>
      <div>{`CloseButtonOrbit HighlightFix and ClickFix`}</div>
      <div>{`CloseButtons for Orbit and CharSwitch`}</div>
      <div>{`StadlPaura to primär Museum`}</div>
      <div>{`TimeMachines have Orbit UI`}</div>
      <div>{`Bug Fixes when start BrowserWindow is small`}</div>
      <div>{`Graphic Bug Fixes "Salzabbau"Handelswege`}</div>
      <div>{`Homebutton`}</div>
      <div>{`Scaleing Sekundaermuseen`}</div>
      <div>{`fixing wrong poistions Sekundaermuseen`}</div>
      <div>{`panorama change NHM aussenstelle`}</div>
      <div>{`etc.`}</div>
  
    </div>
      } */}
    
      <CheckLandscapeMode></CheckLandscapeMode>
    {/* <Loader></Loader> */}

    </>
    </CacheBuster>
  );
}
