
import "./CloseButtonLowModelOrbit.css"
import { stateIframeWindow, statePlayerPositionS} from '../store';

const CloseButtonLowModelOrbit = () => {
  

  const handleClose = () => {

    stateIframeWindow.LowModelOrbit = false
    statePlayerPositionS.playerInput = true


  };

  return (
    <>
    
       
    
            <button className="closeButtonLowModel" onPointerDown={handleClose}>
            close model view
            </button>
      
      
     
  
    </>
  );
};

export default CloseButtonLowModelOrbit;