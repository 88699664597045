
import "./CloseButtonLowModelOrbit.css"
import { stateOrbitLocation } from '../store';
import Sound from "../Utils/Sound";
import { useSnapshot } from "valtio";

const CloseButtonOrbit = () => {
    const snapStateOrbitLocation = useSnapshot(stateOrbitLocation)
    const {
        playFly,
    
      } = Sound();

  const handleClose = () => {
    if(!snapStateOrbitLocation.lerpRuns){
    playFly()
    stateOrbitLocation.orbitClicked = false
    }
   
  };

  return (
    <>
    
       
    
            <button className="closeButtonLowModel" onPointerDown={handleClose}>
            close orbit view
            </button>
      
      
     
  
    </>
  );
};

export default CloseButtonOrbit;