
import "./ImageGallery.css";
import textData from "../entryRoom/text/imageGalleryText.json";
import textDataEN from "../entryRoom/text/imageGalleryText_en.json";
import { stateImageGallery, stateIframeWindow } from "../store";
import { useSnapshot } from "valtio";
import { useEffect } from "react";
import { useState } from "react";
import { stateEnglisch } from "../store";


export default function ImageGallery() {
const snapStateEnglisch = useSnapshot(stateEnglisch)
  const snapStateImageGallery = useSnapshot(stateImageGallery)

  const [titleA, setTitleA] = useState()
  const [titleB, setTitleB] = useState()
  const [titleC, setTitleC] = useState()
  const [titleD, setTitleD] = useState()

  const [textA, setTextA] = useState()
  const [textB, setTextB] = useState()
  const [textC, setTextC] = useState()
  const [textD, setTextD] = useState()

  const [picA, setPicA] = useState()
  const [picB, setPicB] = useState()
  const [picC, setPicC] = useState()
  const [picD, setPicD] = useState()

  const [linkA, setLinkA] = useState()
  const [linkB, setLinkB] = useState()
  const [linkC, setLinkC] = useState()
  const [linkD, setLinkD] = useState()

  const [linkTitelA, setLinkTitelA] = useState()
  const [linkTitelB, setLinkTitelB] = useState()
  const [linkTitelC, setLinkTitelC] = useState()
  const [linkTitelD, setLinkTitelD] = useState()

  const [bValid, setBvalid] = useState()
  const [cValid, setCvalid] = useState()
  const [dValid, setDvalid] = useState()


useEffect(()=>{
  let imageData

if(snapStateEnglisch.isEnglisch){


  imageData = textDataEN.find(item => item.Id === snapStateImageGallery.galleryIndex);
}
else{
  imageData = textData.find(item => item.Id === snapStateImageGallery.galleryIndex);
}



  
  if (imageData) {
    // Check if the Images array is present
    const imagesExist = !!imageData.Images;

    // Set titleA if Images array is present
    setTitleA(imagesExist ? imageData.Images[0].Title : "");
    setTextA(imagesExist ? imageData.Images[0].Text : "");
    setPicA(imagesExist ? imageData.Images[0].Pic : "");
    setLinkA(imagesExist ? imageData.Images[0].Link : "");
    setLinkTitelA(imagesExist ? imageData.Images[0].LinkTitel : "");

    // setTitleB(imagesExist ? imageData.Images[0].TitelA : "");
    // setTitleC(imagesExist ? imageData.Images[0].TitelA : "");
;

    // Set titleB and bValid based on the existence of Images array
    if (imagesExist) {
      const secondImageExistsAndValid = imagesExist && imageData.Images[1]
      const thirdImageExistsAndValid = imagesExist && imageData.Images[2]
      const fourthImageExistsAndValid = imagesExist && imageData.Images[3]

      setTitleB(imageData.Images[1] ? imageData.Images[1].Title : "");
      setTextB(imageData.Images[1] ? imageData.Images[1].Text : "");
      setPicB(imageData.Images[1] ? imageData.Images[1].Pic : "");
      setLinkB(imageData.Images[1] ? imageData.Images[1].Link : "");
      setLinkTitelB(imageData.Images[1] ? imageData.Images[1].LinkTitel : "");
      setBvalid(secondImageExistsAndValid);

      setTitleC(imageData.Images[2] ? imageData.Images[2].Title : "");
      setTextC(imageData.Images[2] ? imageData.Images[2].Text : "");
      setPicC(imageData.Images[2] ? imageData.Images[2].Pic : "");
      setLinkC(imageData.Images[2] ? imageData.Images[2].Link : "");
      setLinkTitelC(imageData.Images[2] ? imageData.Images[2].LinkTitel : "");
      setCvalid(thirdImageExistsAndValid)

      setTitleD(imageData.Images[3] ? imageData.Images[3].Title : "");
      setTextD(imageData.Images[3] ? imageData.Images[3].Text : "");
      setPicD(imageData.Images[3] ? imageData.Images[3].Pic : "");
      setLinkD(imageData.Images[3] ? imageData.Images[3].Link : "");
      setLinkTitelD(imageData.Images[3] ? imageData.Images[3].LinkTitel : "");
      setDvalid(fourthImageExistsAndValid)


    } 
  } else {
   
  }



},[snapStateImageGallery.galleryOn])

const [fullscreenImage, setFullscreenImage] = useState(null);

const handleCloseFullImage = () => {
  setFullscreenImage(false);

};

const handleClose = () => {
  stateImageGallery.galleryIndex = ""
  stateImageGallery.galleryOn = false

  stateIframeWindow.renderCanvas = true

};

const handleImageClick = (pic) => {
  setFullscreenImage(pic);

};
  return (
    <>
{/* partial:index.partial.html  */}
{snapStateImageGallery.galleryOn &&  <div className="iframeContainer">
<fieldset className="slideshow">

  {/* Slide 1 */}
  <input type="radio" id="slideCheckbox1" name="slide" defaultChecked autoFocus ></input>

  <div className="slide" style={{ backgroundImage: `url(${picA})` }} >

  <div className="slideTrigger" onClick={() => handleImageClick(picA)}>

  </div>
  {/* <img className="slide__html"  src={picA}></img> */}
    <div className="slide__html">

    </div>
    <div className="slide__content"  >
    <div>
      <div className="modal__titlePic">{titleA}</div>
      </div>
      <div className="GalleryText">
      <div className="modal__textPic">{textA} </div>
  
      </div>
 {  linkA  ? <> <br />
    Zu finden im:<br />
        <a  href={linkA} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline'}}>{linkTitelA}</a>
         <br /><br /><br /></> : <> <br />
        &nbsp;<br />
      
        </>}
 
    </div>  
  </div>

 {/* Slide 2  */}
  <input type="radio" id="slideCheckbox2" name="slide"></input>
  <div className="slide" style={{ backgroundImage: `url(${picB})` }} onClick={() => handleImageClick(picB)}>
  <div className="slideTrigger" onClick={() => handleImageClick(picB)}>

</div>
    
    <div className="slide__content" >
    <div>
    <div className="modal__titlePic">{titleB}</div>
      </div>
      <div className="GalleryText">
      <div className="modal__textPic">{textB}</div>
  
      </div>
 {   linkB  ?  <> <br />
   Zu finden im:<br />
        <a href={linkB} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>{linkTitelB}</a>
        <br /><br /><br /></> : <> <br />
        &nbsp;<br /><br />
       
        </>}
 
    </div>   
  </div>
  {/* Slide 3  */}
  <input type="radio" id="slideCheckbox3" name="slide"></input>
  <div className="slide" style={{ backgroundImage: `url(${picC})` }}  onClick={() => handleImageClick(picC)}>
   
  <div className="slideTrigger" onClick={() => handleImageClick(picC)}>

</div>
   
    <div className="slide__content">
    <div>
    <div className="modal__titlePic">{titleC}</div>
      </div>
      <div className="GalleryText">
      <div className="modal__textPic">{textC}</div>
  
      </div>

   {  linkC  ?  <> <br />
    Zu finden im:<br />
        <a href={linkC} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>{linkTitelC}</a>
        <br /><br /><br /></> : <> <br />
        &nbsp;<br />
      
        </>}
   
    </div>   
  </div>

 {/* Slide 4 */}
  <input type="radio" id="slideCheckbox4" name="slide"></input>
  <div className="slide" style={{ backgroundImage: `url(${picD})`}}  onClick={() => handleImageClick(picD)}>
   
  <div className="slideTrigger" onClick={() => handleImageClick(picD)}>

</div>
   
    <div className="slide__content">
    <div>
    <div className="modal__titlePic">{titleD}</div>
      </div>
      <div className="GalleryText">
      <div className="modal__textPic">{textD}</div>
 
      </div>
{   linkD  ?  <> <br />
    Zu finden im:<br />
        <a href={linkD} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>{linkTitelD}</a>
        <br /><br /><br /></> :  <> <br />
        &nbsp;<br />
      
        </>}
 
    </div>  
  </div>
  <button className="closeButtonSlide" onPointerDown={handleClose}>
              CLOSE
              </button>



  <nav>
    
   
<label className="slide-button" htmlFor="slideCheckbox1">Picture1</label>
 { picB &&  <label className="slide-button" htmlFor="slideCheckbox2">Picture2</label>}
 { picC &&  <label className="slide-button" htmlFor="slideCheckbox3">Picture3</label>}
 { picD && <label className="slide-button" htmlFor="slideCheckbox4">Picture4</label>}
   
  </nav>

</fieldset>

</div>}

{fullscreenImage && (

              <div className="fullscreen-image" style={{ backgroundImage: `url(${fullscreenImage})` }} onClick={handleCloseFullImage}>
                {/* Additional content if needed */}
              </div>
         
          )}

    </>
  );
}
